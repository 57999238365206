<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{$t('module_settings.module_settings')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
            </div>

            <div class="card-body">
                <div class="form-group row">


                            <div class="col-lg-6 mb-5">
                                <label>{{$t('module_settings.module_name')}}<span class="text-danger">*</span></label>
                                <input type="text" v-model="data.module_name" class="form-control" :class="validation && validation.module_name ? 'is-invalid' : ''" :placeholder="$t('module_settings.module_name')"/>
                                <span v-if="validation && validation.module_name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.module_name[0] }}
                                    </span>
                            </div>

                            <div class="col-lg-6 mb-5">
                                <label>{{$t('module_settings.general_name')}}<span class="text-danger">*</span></label>
                                <input type="text" v-model="data.general_name" class="form-control" :class="validation && validation.general_name ? 'is-invalid' : ''" :placeholder="$t('module_settings.general_name')"/>
                                <span v-if="validation && validation.general_name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.general_name[0] }}
                                    </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('module_settings.summery')}}</label>
                                <textarea v-model="data.summery" class="form-control" :class="validation && validation.summery ? 'is-invalid' : ''" :placeholder="$t('module_settings.summery')"></textarea>
                                <span v-if="validation && validation.summery" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.summery[0] }}
                                    </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('module_settings.details')}}</label>
                                <textarea v-model="data.details" class="form-control" :class="validation && validation.details ? 'is-invalid' : ''" :placeholder="$t('module_settings.details')"></textarea>
                                <span v-if="validation && validation.details" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.details[0] }}
                                    </span>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>{{$t('module_settings.upload_image')}}</label>
                                <div class="form-group">
                                    <upload-file
                                            @file="listenerAttachment"
                                            :inner-id="'image'"
                                            :placeholder="$t('module_settings.upload_image')"
                                            :upload="dir_upload"
                                            :start-link="'base'"
                                            v-bind:valueprop="{name:'image',path:image_url}" v-if="reloadUploadAttachment">
                                    </upload-file>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-5">
                                <label>&ensp;</label>
                                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
                            </div>

                        </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                    </div>
                </div>
            </div>

        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {

        data() {
            return {
                mainRoute: '/settings/module-settings',
                mainRouteDependency: 'base/dependency',
                dir_upload: 'images',
                idEdit: this.$route.params.id ? this.$route.params.id : '',
                data: {
                    module_name: null,
                    general_name: null,
                    summery: null,
                    details : null,
                    image : null,
                    is_active: false,
                },
                isEditing: false,
                validation: null,
                image_url: null,
                reloadUploadAttachment: true,
            };
        },

        methods: {
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(this.mainRoute, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push({name: 'module-settings.index'});
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            update() {
                ApiService.put(this.mainRoute + '/' + this.idEdit, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push({name: 'module-settings.index'});
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            getData() {
                this.reloadUploadAttachment = false;
                ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
                    this.isEditing = true;
                    this.data.module_name = response.data.data.module_name;
                    this.data.general_name = response.data.data.general_name;
                    this.data.summery = response.data.data.summery;
                    this.data.details = response.data.data.details;
                    this.data.image = response.data.data.image;
                    this.data.is_active = response.data.data.is_active;
                    this.image_url = response.data.data.image_url;
                    this.reloadUploadAttachment = true;
                });
            },
            listenerAttachment(event) {
                if (event) {
                    this.image_url = event.pathDB;
                    this.data.image = event.name;
                }
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.module_settings"), route:{name: 'module-settings.index'}}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

            if (this.idEdit) {
                this.getData();
            }
        },
    };
</script>


